import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {FullScreenLayoutComponent} from './infraestructure/layout/full-screen-layout/full-screen-layout.component';
import {HeaderLayoutComponent} from './infraestructure/layout/header-layout/header-layout.component';
import {AuthGuard} from './application/shared/modules/authentication/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: HeaderLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'ordenes-fabricacion',
      },
      {
        path: 'ordenes-fabricacion',
        loadChildren:  () => import('./domain/ordenes-fabricacion/ordenes-fabricacion.module').then(m => m.OrdenesFabricacionModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'auth',
    component: FullScreenLayoutComponent,
    data: {
      title: 'login'
    },
    children: [
      {
        path: 'login',
        loadChildren: () => import('./domain/login/login.module').then(m => m.LoginModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
