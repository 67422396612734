import { Component } from '@angular/core';
import {environment} from '../environments/environment';
import {AuthenticationService} from './application/shared/modules/authentication/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})

export class AppComponent {
  title = 'jauja-frontend';

  constructor(public authenticationService: AuthenticationService) {
    this.authenticationService.authUrl = environment.authUrl;

  }
}
