import { Component, OnInit } from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  public isShown = false;
  public form: UntypedFormGroup;

  constructor() {
  }

  public ngOnInit(): void {

  }

  public toggle(): void {
    this.isShown = !this.isShown;
  }
}
