import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {UsuarioRegistado} from '../../../../../application/shared/modules/authentication/usuario-registado.model';
import {AuthenticationService} from '../../../../../application/shared/modules/authentication/authentication.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.sass']
})
export class UserInfoComponent implements OnInit {
  public datosUsuario = {} as UsuarioRegistado;
  public hideUserInfo = true;

  constructor(private elementRef: ElementRef,
              private authenticationService: AuthenticationService) { }

  public ngOnInit(): void {
    this.datosUsuario = (JSON.parse(localStorage.getItem('usuario')) as UsuarioRegistado);
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.hideUserInfo = true;
    } else {
      this.hideUserInfo = !this.hideUserInfo;
    }
  }

  public cerrarSesion(): void {
    this.authenticationService.logOut();
  }

}
