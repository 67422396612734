import {Injectable} from '@angular/core';
import {UsuarioRegistado} from '../../application/shared/modules/authentication/usuario-registado.model';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {
  }

  public getLineaPorDefecto(): any {
    return Number(localStorage.getItem('linea_fabricacion'));
  }

  public setLineaPorDefecto(linea: string) {
    localStorage.setItem('linea_fabricacion', linea);
  }
  public getToken(): string {
    return localStorage.getItem('token');
  }
  public getUsuario(): UsuarioRegistado {
    return JSON.parse(localStorage.getItem('usuario'));
  }

  public setToken(newToken: string) {
    localStorage.setItem('token', newToken);
  }
  public guardarFiltroOrdenes(filtro): void {
    localStorage.setItem('filtro_orden', JSON.stringify(filtro));
  }

  public getFiltroOrdenes(): any {
    return JSON.parse(localStorage.getItem('filtro_orden'));
  }

  public removeUserAuthInfo(): void {
    localStorage.clear();
  }


}
