import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderLayoutComponent} from './infraestructure/layout/header-layout/header-layout.component';
import {FullScreenLayoutComponent} from './infraestructure/layout/full-screen-layout/full-screen-layout.component';
import {ReactiveFormsModule} from '@angular/forms';
import {AppResolverService} from './app-resolver.service';
import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import {ValidationService} from './infraestructure/validation/validation.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HeaderComponent} from './infraestructure/layout/header-layout/header/header.component';
import {UserInfoComponent} from './infraestructure/layout/header-layout/header/user-info/user-info.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {ActionConfirmationModalModule} from './infraestructure/shared/modules/action-confirmation-modal/action-confirmation-modal.module';
import {ToastrModule} from 'ngx-toastr';
import {JwtInterceptorService} from './application/shared/modules/authentication/jwt-interceptor.service';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";

registerLocaleData(localeEs, 'es');
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.apiUrl + '/public/langs/' , '');
}
export function startupServiceFactory(appResolverService: AppResolverService): () => void {
  return () => appResolverService.load();
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderLayoutComponent,
    FullScreenLayoutComponent,
    HeaderComponent,
    UserInfoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ActionConfirmationModalModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    ToastrModule.forRoot({
      positionClass : 'toast-bottom-right'
    }),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'es-ES'
    },
    {provide: APP_INITIALIZER, useFactory: startupServiceFactory, deps: [AppResolverService, HttpClient], multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true
    },
    ValidationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
